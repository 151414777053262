<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>

            <div class="row">
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  <code> * </code>
                  <label>ชื่อลูกค้า :</label>
                  <multiselect
                    v-model="cusName"
                    label="nameTh"
                    :options="rowsUser"
                    :show-labels="false"
                    :custom-label="customLabel"
                    open-direction="bottom"
                    @search-change="getDataCustomer"
                    @remove="getRemoveSelected"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.cusName.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.cusName.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.cusName.required"
                      >Please Selected Data.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">ทะเบียน :</label>
                  <multiselect
                    v-model="vin"
                    label="licensePlate"
                    type="search"
                    :options="rowsDataService"
                    :show-labels="false"
                    open-direction="bottom"
                    @search-change="getDataVehicle"
                    placeholder=""
                    class="helo"
                    default
                    :class="{
                      'is-invalid': submitform && $v.vin.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.vin.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vin.required">Please Selected Data.</span>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">เลขตัวถัง :</label>
                  <multiselect
                    v-model="vin"
                    label="vin"
                    :options="rowsDataService"
                    :show-labels="false"
                    :close-on-select="true"
                    :internal-search="true"
                    open-direction="bottom"
                    @search-change="getDataVehicle"
                    placeholder=""
                    :class="{
                      'is-invalid': submitform && $v.vin.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.vin.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vin.required">Please Selected Data.</span>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">สถานะ :</label>
                  <multiselect
                    v-model="status"
                    label="text"
                    type="search"
                    :options="optionsAppointment"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    class="helo"
                    default
                    :class="{
                      'is-invalid': submitform && $v.status.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.status.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.status.required"
                      >Please Selected Data.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-2 col-lg-2">
                <div class="mb-3 position-relative">
                  <label for="validation">เบอร์โทรศัพท์ :</label>
                  <input
                    v-model="cusMobilePhone"
                    type="text"
                    class="form-control"
                    :maxlength="10"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-2">
                <div class="mb-3 position-relative">
                  <code>* </code
                  ><label for="validation">วันที่เข้ารับบริการ :</label>
                  <date-picker
                    v-model="apptDate"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :class="{
                      'is-invalid': submitform && $v.apptDate.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitform && $v.apptDate.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.apptDate.required"
                      >Please Selected Data.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-2 col-lg-2">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">เวลาเข้า :</label>
                  <date-picker
                    v-model="apptTime"
                    format="HH:mm"
                    value-type="format"
                    type="time"
                  >
                  </date-picker>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-3">
                <div class="mb-3 position-relative">
                  <code>* </code
                  ><label for="validation">ประเภทการนัดหมาย :</label>
                  <b-form-checkbox-group
                    v-model="selectedType"
                    :options="rowsApptype"
                    class="mb-3"
                    value-field="apptTypeId"
                    text-field="nameTh"
                    disabled-field="notEnabled"
                  ></b-form-checkbox-group>
                </div>
              </div>
              <div class="col-6 col-sm-2 col-lg-3">
                <div class="mb-3 position-relative">
                  <code>* </code>
                  <label for="validation">หมายเหตุ :</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="apptTypeRemark"
                    placeholder="กรณีที่ต้องการพิมพ์เรื่องเข้ามา"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  <code>* </code>
                  <label for="validationTooltiptype">ประเภทการเช็คระยะ</label
                  ><br />
                  <multiselect
                    v-model="distances"
                    :options="rowDataSd"
                    label="nameTh"
                    :class="{
                      'is-invalid': submitform && $v.distances.$error,
                    }"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                  <div
                    v-if="submitform && $v.distances.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.distances.required"
                      >Please Selected Data.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-2">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">ช่องจอด :</label>
                  <multiselect
                    v-model="pslotId"
                    label="nameTh"
                    :options="rowsPslot"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    class="helo"
                    default
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-6 col-sm-3 col-md-2">
                <div class="mb-3 position-relative">
                  <code>* </code><label for="validation">Slot :</label>
                  <multiselect
                    v-model="fslotId"
                    label="nameTh"
                    :options="rowsFslot"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    class="helo"
                    default
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-6 col-sm-2 col-lg-4">
                <div class="mb-3 position-relative">
                  <label for="validation">หมายเหตุ :</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="remark"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md">
                <button class="btn btn-success float-end" @click="tooltipForm">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </Layout>
</template>

<script>
// import vue2Dropzone from "vue2-dropzone";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";

/**
 * Add-product component
 */
export default {
  page: {
    title: "สร้างการนัดหมาย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      selectedType: [],
      rowsUser: [],
      rowsService: [],
      rowsDataService: [],
      rowsMasterSD: [],
      rowsApptype: [],
      rowsFslot: [],
      rowsPslot: [],
      lisencePlate: "",
      vin: "",
      cusName: "",
      apptDate: "",
      submitform: false,
      status: "",
      apptTime: "",
      apptTypeRemark: "",
      remark: "",
      cusMobilePhone: "",
      apptTypeId: "",
      pslotId: "",
      fslotId: "",

      items: [
        {
          text: "ลูกค้าสัมพันธ์",
        },
        {
          text: "การนัดหมาย",
          href: '/appointment' 
        },
        {
          text: "สร้างการนัดหมาย",
          active: true,
        },
      ],

      optionsAppointment: [
        {
          text: "นัดหมาย",
          status: "APPT",
        },
        {
          text: "ยืนยันการนัดหมาย",
          status: "CONF",
        },
      ],
      title: "สร้างการนัดหมาย",
      form: {
        fname: "",
        lname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      distances:[],
      rowDataSd:[],
      localDataBranch: [],
      localDataOne: "",
      rows: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
      overlayFlag: false,
    };
  },

  validations: {
    vin: {
      required,
    },
    cusName: {
      required,
    },
    status: {
      required,
    },
    apptDate: {
      required,
    },
    distances:{

    }
  },
  mounted() {
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // // // console.log(user);
  },
  created() {
    this.getLocalData();
    this.getDataAppoinType();
    this.getDataFslot();
    this.getDataPslot();
    this.getDataSd();
  },
  methods: {
    getDataSd: function () {
      this.loading = true;
      useNetw
        .get("api/master/service-distances", {}) // เอามาจากไหน

        .then((response) => {
          this.rowDataSd = response.data.data;
          // // // console.log("SD = ", this.rowDataSd);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    // getData: function() {
    //   // this.overlayFlag = true;
    //   this.loading = true; //skeleton true
    //   useNetw
    //     .get("api/customers", {
    //       params: {
    //         page: 1,
    //         perPage: 100,
    //         branchId: [1],
    //         nameTh: this.nameCus.nameTh,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsUser = response.data.data;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       // this.overlayFlag = false;
    //       this.loading = false; //skeleton false
    //     });
    // },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.localDataOne = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      // // // console.log(this.localDataBranch);
      // // // console.log('localAldata = ',user);
    },

    getDataAppoinType: function () {
      useNetw
        .get("api/master/appointment-types", {})
        .then((response) => {
          this.rowsApptype = response.data.data;
          // // console.log("rowsApptype", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataFslot: function () {
      useNetw
        .get("api/master/flow-slots", {
          params: {
            branchId: this.localDataOne,
          },
        })
        .then((response) => {
          this.rowsFslot = response.data.data;
          // // console.log("fslot", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataPslot: function () {
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.localDataOne,
          },
        })
        .then((response) => {
          this.rowsPslot = response.data.data;
          // // console.log("pslot", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    submitAppointment: function () {
      useNetw
        .post("/api/appointment/store", {
          branchId: this.localDataOne,
          vehicleId: this.vin.vehicleId,
          cusId: this.cusName.cusId,
          status: this.status.status,
          apptDate: this.apptDate,
          apptTime: this.apptTime,
          apptTypeRemark: this.apptTypeRemark,
          remark: this.remark,
          cusName: this.cusName.nameTh,
          cusMobilePhone: this.cusMobilePhone,
          vehiclePlate: this.vin.licensePlate,
          apptTypeId: this.selectedType,
          pslotId: this.pslotId.pslotId,
          fslotId: this.fslotId.fslotId,
          distanceId: this.distances.distanceId
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            //// // console.log(response),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "appointment" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    getDataVehicle: function () {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: [this.localDataOne],
          },
        })
        .then((response) => {
          this.rowsDataService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDataCustomer: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.localDataOne],
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRemoveSelected: function () {
      this.isLoading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.localDataOne],
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitAppointment();
      }
    },
  },
  middleware: "authentication",
};
</script>
